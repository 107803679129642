<section style="padding: 16px;">
  <mat-card-title>
    <h1 style="text-align: center; margin-bottom: 0;">
      Scan Me
    </h1>
  </mat-card-title>
  <div style="text-align: center;">
    <qr-code [value]="data.cart.cid" [size]="256" [errorCorrectionLevel]="'M'"></qr-code>
  </div>
  <mat-divider style="margin: 8px auto;"></mat-divider>
  <mat-card-subtitle>
    <h4>
      KITS ON TICKET
    </h4>
  </mat-card-subtitle>
  @for (k of data.cart.unclaimed; track k) {
    <mat-card appearance="outlined" style="margin-bottom: 4px;">
      <div class="ticket-container">
        <div class="icon">
          <img src="../../../assets/kit.png" width="65px">
        </div>
        <div class="details">
          <mat-card-subtitle>
            <h3 style="margin: 0;">
              <b>SIZE:</b> {{k.size}}
            </h3>
            <p>
              <b>KITS:</b> {{k.kits}}
            </p>
          </mat-card-subtitle>
        </div>
        <div class="action">
        </div>
      </div>
    </mat-card>
  }

  <button style="margin-top: 16px;" class="full-width" mat-stroked-button color="warn" (click)="close()">
    Close Details
  </button>
</section>