import { Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TicketingService } from '../services/ticketing.service';
import {  CartItem, ClubFilter, EditionCart, EditionClub, MakeCart } from '../models/ticketing.models';
import { Router } from '@angular/router';
import { editionTicketPrice } from '../global/variables';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

    storedCart?: MakeCart
    cart?: EditionCart
    cartItems?: CartItem[] = []
    clubs: EditionClub[] = []
    cartPrice = 0
    userForm?: FormGroup
    ckind = ''
    district = ''
    showPayment = false
    referer = ''

    // injections
    #service = inject(TicketingService)
    #router = inject(Router)
    #formBuilder = inject(FormBuilder)
    #platformId = inject(PLATFORM_ID)

    ngOnInit(): void {
        if (isPlatformBrowser(this.#platformId)) {
            const s = localStorage.getItem("cart")
            this.referer = localStorage.getItem("referer") ?? ''
            if (s) {
                this.storedCart = JSON.parse(s)
                this.cartItems = this.storedCart?.items
            } else {
                this.#router.navigate([''])
            }
        }
        // this._sendCart()
        this.#calculateTotal()
        this.#buildForm()
    }

    complete(cartId: number) {
        if(this.cart){
            
            this.#service.getCart(cartId).subscribe({
                next: data => {
                    if (data.status !== 'PENDING') {
                        if (isPlatformBrowser(this.#platformId)) {
                            localStorage.removeItem("cart")
                        }
    
                        this.#router.navigate([''])
                    }
                }
            })
        }
    }

    onRemove(item: CartItem) {
        this.cartItems = this.cartItems?.filter(i => i.size !== item.size)
        this.#calculateTotal()
    }

    onChange(item: CartItem) {
        this.cartItems = this.cartItems?.filter(i => i.size !== item.size)
        this.cartItems?.push(item)
        this.#calculateTotal()
    }

    onSubmitUser() {
        if (this.userForm?.valid && this.storedCart) {
            this.storedCart.ckind = this.userForm.value.ckind
            this.storedCart.race = this.userForm.value.race
            if (this.userForm.value.ckind === 'ROTARY' || this.userForm.value.ckind === 'ROTARACT') {
                this.storedCart.cname = this.userForm.value.cname
                this.storedCart.district = this.userForm.value.district
            }
            this.storedCart.referer = this.userForm.value.referer
            this.showPayment = true
        }
    }
    ckindChange(e: MatSelectChange) {
        this.ckind = e.value
        if (this.ckind !== 'OTHER' && this.clubs.length > 0) {
            let cFilter: ClubFilter = {
                ckind: this.ckind,
                district: this.district
            }
            this.#service.getClubs(cFilter).subscribe({
                next: d => {
                    this.clubs = d
                }
            })
        }
    }

    districtChange(e: MatSelectChange) {
        this.district = e.value
        if (this.ckind !== 'OTHER' && e.value !== 'OTHER') {
            let cFilter: ClubFilter = {
                ckind: this.ckind,
                district: e.value
            }
            this.#service.getClubs(cFilter).subscribe({
                next: d => {
                    this.clubs = d
                }
            })
        } else {
            this.clubs = []
        }
    }


    #calculateTotal() {
        this.cartPrice = 0
        if (this.cartItems) {
            for (let i of this.cartItems) {
                this.cartPrice += (+i.count * +editionTicketPrice)
            }
            this.cartItems.sort((a, b) => a.size - b.size)
            if (this.cartItems.length === 0) {
                if (isPlatformBrowser(this.#platformId)) {
                    localStorage.removeItem('cart')
                }

                this.#router.navigate(['/'])
            }
        }
    }

    #buildForm() {
        this.userForm = this.#formBuilder.group({
            ckind: ['', Validators.required],
            cname: [''],
            district: [''],
            race: ['21'],
            referer: [this.referer]
        })
        this.#validators()
    }

    #validators() {
        if (this.userForm) {
            this.userForm.get('ckind')?.valueChanges.subscribe(
                value => {
                    const cname = this.userForm?.get('cname')!
                    const district = this.userForm?.get('district')!
                    if (value === 'ROTARY' || value === 'ROTARACT') {
                        cname.setValidators(Validators.required)
                        district.setValidators(Validators.required)
                    } else {
                        console.log("clearing")
                        cname.clearValidators()
                        district.clearValidators()
                    }
                    cname.updateValueAndValidity()
                    district.updateValueAndValidity()
                }
            )
        }

    }

}
