import { Observable ,  BehaviorSubject, throwError } from 'rxjs';
import {take, filter, catchError, switchMap, finalize} from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from "@angular/common/http";

import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Token } from '../models/authx.models';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(
    private injector: Injector,
    private router: Router
  ) { }

  // add token to request header
  addToken(req: HttpRequest<any>, token:string): HttpRequest<any> {
    if(token === ""){
      return req;
    }
    return req.clone({
      setHeaders: {Authorization: `Bearer ${token}`}
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const authService = this.injector.get(AuthService);
    return next.handle(this.addToken(req,authService.accessToken())).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse){
          switch((<HttpErrorResponse>error).status){
            case 401:
                return this.handle401Error(req,next);
            default:
                return this.handleOtherError(error);
          }
        }
        else {
          return throwError(() => error)
        }
      })
    );
  }

  handleOtherError(error: HttpErrorResponse){
    return throwError(() => error)
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler){
    if(!this.isRefreshingToken){
      this.isRefreshingToken = true;

      //reset so that the subsequent requests wait for a new token

      this.tokenSubject.next("");

      const authService = this.injector.get(AuthService);

      return authService.iTokenRefresh().pipe(
        switchMap((resp: Token) => {
          if(resp){
            this.tokenSubject.next(resp.access_token)
            authService.completeLogin(resp.access_token,resp.refresh_token);
            return next.handle(this.addToken(req,resp.access_token));
          }

          return this.logoutUser();
        }),
        catchError(error => {
          return this.logoutUser();
        }),
        finalize(()=>{
          this.isRefreshingToken = false;
        }),
      );
    }
    else{
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req,token));
        }),
      );
    }
  }
 

  logoutUser() {
    //route to login page
    this.router.navigate(['/auth']);
    return throwError(() => {"Not Authorized to view this page"})
  }
}
