import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-card-complete',
    templateUrl: './card-complete.component.html',
    styleUrls: ['./card-complete.component.scss']
})
export class CardCompleteComponent implements OnInit{
    status = 'pending'
    #route = inject(ActivatedRoute) 
    
    ngOnInit(): void {
        this.#route.queryParams.subscribe(
            d => {
                if (d['status']) {
                    this.status = d['status']
                }
            }
        )
    }
}
