<div class="flex-container">
  <div class="flex-item">
    <img routerLink="/" class="login-logo" src="../../assets/rcr.png" alt="RCPU Logo">
    <!-- start -->
    <mat-card style="padding: 16px" appearance="outlined">
      <mat-card-title>
        <h1 style="text-align: center;">
          Cart Checkout
        </h1>
      </mat-card-title>
      @for (kit of cartItems; track kit) {
        <app-kit-size (removeItemEvent)="onRemove($event)" (changeItemEvent)="onChange($event)" [kit]="kit"></app-kit-size>
      }
      <!-- <p>
      <b>Cart Total: </b>
    </p> -->
    <mat-card-title>
      <h2>
        <b>Cart Total: </b> {{cart?.currency}} {{cartPrice | number}}
      </h2>
    </mat-card-title>
    @if (userForm) {
      <form [formGroup]="userForm" (ngSubmit)="onSubmitUser()">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Preferred Race (Optional)</mat-label>
          <mat-select  matInput formControlName="race">
            <mat-option value="21">21 KM</mat-option>
            <mat-option value="10">10 KM</mat-option>
            <mat-option value="5">5 KM</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>I am a </mat-label>
          <mat-select  matInput formControlName="ckind" (selectionChange)="ckindChange($event)">
            <mat-option value="ROTARY">Rotarian</mat-option>
            <mat-option value="ROTARACT">Rotaractor</mat-option>
            <mat-option value="OTHER">Well Wisher</mat-option>
          </mat-select>
        </mat-form-field>
        @if (ckind === 'ROTARY' || ckind === 'ROTARACT') {
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>RI District</mat-label>
            <mat-select  matInput formControlName="district" (selectionChange)="districtChange($event)">
              <mat-option value="9213">RID 9213</mat-option>
              <mat-option value="9214">RID 9214</mat-option>
              <mat-option value="OTHER">OTHER</mat-option>
            </mat-select>
          </mat-form-field>
        }
        @if ((ckind === 'ROTARY' || ckind === 'ROTARACT') && district !== 'OTHER' && clubs.length > 0) {
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Club</mat-label>
            <mat-select  matInput formControlName="cname">
              @for (club of clubs; track club) {
                <mat-option [value]="club.name">{{club.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        @if ((ckind === 'ROTARY' || ckind === 'ROTARACT') && district === 'OTHER') {
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Enter RI District</mat-label>
            <input type="number" matInput placeholder="e.g. 9214" formControlName="district">
          </mat-form-field>
        }
        @if ((ckind === 'ROTARY' || ckind === 'ROTARACT') && district === 'OTHER') {
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Club Name</mat-label>
            <input type="text" matInput placeholder="e.g. Bukoto" formControlName="cname">
          </mat-form-field>
        }
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Referral Code (Optional)</mat-label>
          <input type="text" matInput placeholder="e.g. 1" formControlName="referer">
        </mat-form-field>
        @if (!showPayment) {
          <button [disabled]="userForm.invalid" class="full-width" mat-flat-button color="accent"
            type="submit">
            Continue to Make Payment
          </button>
        }
      </form>
    }

    @if (showPayment && storedCart) {
      <mat-tab-group>
        <mat-tab label="Card">
          <app-card (completed)="complete($event)" [amount]="cartPrice" [storedCart]="storedCart"></app-card>
        </mat-tab>
        <mat-tab label="Mobile Money">
          <app-momo (completed)="complete($event)" [amount]="cartPrice" [storedCart]="storedCart"></app-momo>
        </mat-tab>
      </mat-tab-group>
    }
  </mat-card>
</div>
</div>