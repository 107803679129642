import { Component, inject, OnInit } from '@angular/core';
import { TicketingService } from '../services/ticketing.service';
import { ClubKits } from '../models/leaderboard.models';
import { Meta, Title } from '@angular/platform-browser';
import { siteDetails } from '../global/variables';

@Component({
    selector: 'app-leaderboard',
    templateUrl: './leaderboard.component.html',
    styleUrl: './leaderboard.component.scss',
    host: { ngSkipHydration: 'true' }
})
export class LeaderboardComponent implements OnInit {
    nextPage?: number = 1
    kits: ClubKits[] = []
    loading = false
    message = "Load More Clubs"

    // injections
    #service = inject(TicketingService)
    #meta = inject(Meta) 
    #titleService = inject(Title) 

    ngOnInit() {
        this.#setMetaData()
        this.#getLeaderboard()
    }

    getNextPage(){
        this.#getLeaderboard()
    }

    #getLeaderboard() {
        if (this.nextPage) {
            this.message = "Loading..."
            this.loading = true
            this.#service.getLeaderboard(this.nextPage).subscribe({
                next: data => {
                    for (let c of data.clubs) {
                        this.kits.push(c)
                    }
                    this.nextPage = data.pagination.next
                    this.message = "Load More Clubs"
                    this.loading = false
                }
            })
        }
    }

    #setMetaData() {
        this.#titleService.setTitle(`Cancer Run - Leaderboard`)
        this.#meta.addTags([
            { name: 'twitter:card', content: "summary_large_image" },
            { name: 'twitter:title', content: `Cancer Run - Leaderboard` },
            { name: 'twitter:description', content: siteDetails.description },
            { name: 'twitter:image', content: siteDetails.large_img },
            { name: 'twitter:image:alt', content: siteDetails.image_alt },
            { name: 'twitter:site', content: siteDetails.handle },
            { name: 'twitter:creator', content: siteDetails.handle },
            { name: 'og:title', content: `Cancer Run - Leaderboard` },
            { name: 'og:url', content: siteDetails.url },
            { name: 'og:description', content: siteDetails.description },
            { name: 'og:image', content: siteDetails.large_img },
            { name: 'og:image:alt', content: siteDetails.image_alt },
            { name: 'author', content: siteDetails.handle }
        ]);
    }
}
