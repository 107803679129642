import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthxComponent } from './authx/authx.component';
import { LandingComponent } from './landing/landing.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AuthGuard } from './guards/route-guard.service';
import { CardCompleteComponent } from './card-complete/card-complete.component';
import { PurchasedComponent } from './purchased/purchased.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';

const routes: Routes = [
  {
    path:'',
    component:LandingComponent
  },
  {
    path:'leaderboard',
    component:LeaderboardComponent
  },
  {
    path:'auth',
    component:AuthxComponent
  },
  {
    path:'card-complete',
    component: CardCompleteComponent
  },
  {
    path:'checkout',
    component:CheckoutComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'purchased',
    component:PurchasedComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'donations',
    loadChildren: () => import('./donation/donation.module').then(m => m.DonationModule)
  },
  {
    path: 'buy',
    loadChildren: () => import('./buy/buy.module').then(m => m.BuyModule)
  },
  {
    path: 'corporate',
    loadChildren: () => import('./breakfast/breakfast.module').then(m => m.BreakfastModule)
  },
  {
    path: 'sell',
    loadChildren: () => import('./sell/sell.module').then(m => m.SellModule),
    canActivate:[AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
