import { inject, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { authBaseUrl } from '../global/variables';
import { RegisterModel, MessageModel, CheckUser, LoginModel, UserChecked, Token } from '../models/authx.models';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    #http = inject(HttpClient)
    #platformId = inject(PLATFORM_ID)

    registerNewUser(payload: RegisterModel): Observable<MessageModel> {
        return this.#http.post<MessageModel>(`${authBaseUrl}login/new/`, payload)
    }

    getUserOTP(payload: CheckUser): Observable<MessageModel> {
        return this.#http.post<MessageModel>(`${authBaseUrl}login/existing/`, payload)
    }

    loginUser(payload: LoginModel): Observable<Token> {
        return this.#http.post<Token>(`${authBaseUrl}login/`, payload)
    }

    // check availability of email address
    checkUserExistance(payload: CheckUser): Observable<UserChecked> {
        return this.#http.post<UserChecked>(`${authBaseUrl}login/check/`, payload)
    }

    // Token Refresh from Interceptor
    iTokenRefresh(): Observable<any> {
        let token = this.refreshToken();
        if (token === "") {
            return throwError(() => { });
        }
        if (isPlatformBrowser(this.#platformId)) {
            localStorage.removeItem("a");
        }

        return this.#http.post(`${authBaseUrl}refresh-token/`, {
            "token": this.refreshToken()
        });
    }

    //Store in local storage
    completeLogin(access: string, refresh: string) {
        if (isPlatformBrowser(this.#platformId)) {
            localStorage.setItem("a", access);
            localStorage.setItem("r", refresh);
        }

    }

    //get access token
    accessToken(): string {
        var token = ""
        if (isPlatformBrowser(this.#platformId)) {
            token = localStorage.getItem("a") ?? ""
        }
        return token
    }

    // get refreshToken
    private refreshToken(): string {
        var token = ""
        if (isPlatformBrowser(this.#platformId)) {
            token = localStorage.getItem("r") ?? ""
        }
        return token
    }
}
