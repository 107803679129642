import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { httpInterceptorProviders } from './guards/interceptors';
import { AuthxComponent } from './authx/authx.component';
import { LandingComponent } from './landing/landing.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CardCompleteComponent } from './card-complete/card-complete.component';
import { PurchasedComponent } from './purchased/purchased.component';
import { PurchasedDetailComponent } from './purchased-detail/purchased-detail.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { QrCodeModule } from 'ng-qrcode';

@NgModule({
  declarations: [
    AppComponent,
    AuthxComponent,
    LandingComponent,
    CheckoutComponent,
    CardCompleteComponent,
    PurchasedComponent,
    PurchasedDetailComponent,
    LeaderboardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    QrCodeModule
  ],
  providers: [
    httpInterceptorProviders,
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }