<rcr-nav>
    <section style="padding: 12px;">
        <div class="flex-container">
            <div class="flex-item">
                <mat-card-title>
                    <h1 style="text-align: center;">
                        CLUBS LEADERBOARD
                    </h1>
                </mat-card-title>
                <table id="leaderboard">
                    <thead>
                        <tr>
                            <th>Club Name (District)</th>
                            <th>Kits</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (kit of kits; track $index) {
                            <tr>
                                <td>{{kit.club}} ({{kit.district}})</td>
                                <td>{{kit.kits | number}}</td>
                            </tr>
                        }
                    </tbody>
                </table>
                @if(nextPage){
                    <div style="text-align: center; padding: 16px;">
                        <button mat-button color="primary" [disabled]="loading" (click)="getNextPage()">
                            {{message}}
                        </button>
                    </div>
                }
            </div>
        </div>
    </section>
</rcr-nav>