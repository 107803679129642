<div class="flex-container">
  <div class="flex-item">
    <img routerLink="/" class="login-logo" src="../../assets/rcr.png" alt="RCPU Logo">
    <!-- start -->
    @if (startForm) {
      <mat-card appearance="outlined">
        <mat-card-content>
          <form [formGroup]="startForm" (ngSubmit)="onCheck()">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Email Address</mat-label>
              <input matInput placeholder="Email Address" formControlName="email">
              @if (startForm.controls['email'].errors?.['required']) {
                <mat-error>
                  Please Enter valid Email Address
                </mat-error>
              }
              @if (startForm.controls['email'].errors?.['email']) {
                <mat-error>
                  Please Enter valid Email Address
                </mat-error>
              }
            </mat-form-field>
            @if (!requesting) {
              <button [disabled]="startForm.invalid" class="full-width" mat-flat-button
              color="primary" type="submit">Get Started</button>
            }
            @if (requesting) {
              <button disabled class="full-width" mat-flat-button>wait <i
              class="fas fa-circle-notch fa-spin"></i></button>
            }
          </form>
        </mat-card-content>
      </mat-card>
    }

    <!-- new user -->
    @if (newForm) {
      <mat-card appearance="outlined">
        <mat-card-content>
          <mat-card-subtitle>
            <h4>
              Oops! <b>{{email}}</b> doesn't seem to have an account. Please tell us your fullname so we can create one.
            </h4>
          </mat-card-subtitle>
          <form [formGroup]="newForm" (ngSubmit)="onNewUser()">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Please enter your fullname</mat-label>
              <input matInput placeholder="e.g. Thomas Shelby" formControlName="fullname">
              @if (newForm.controls['fullname'].errors?.['required']) {
                <mat-error>
                  Please Enter your Full name
                </mat-error>
              }
            </mat-form-field>
            @if (!requesting) {
              <button [disabled]="newForm.invalid" class="full-width" mat-flat-button
              color="primary" type="submit">Get Started</button>
            }
            @if (requesting) {
              <button disabled class="full-width" mat-flat-button>wait <i
              class="fas fa-circle-notch fa-spin"></i></button>
            }
          </form>
        </mat-card-content>
      </mat-card>
    }

    <!-- login -->
    @if (magicForm) {
      <mat-card appearance="outlined">
        <mat-card-content>
          <mat-card-subtitle>
            <h4>
              To enable you continue, we have sent a One-Time Password to <b>{{email}}.</b>
              If you don't see it, check your SPAM or JUNK mail boxes.
            </h4>
          </mat-card-subtitle>
          <form [formGroup]="magicForm" (ngSubmit)="onLogin()">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Pin</mat-label>
              <input matInput placeholder="Pin" formControlName="pin">
              @if (magicForm.controls['pin'].errors?.['required']) {
                <mat-error>
                  Please Enter valid Pin
                </mat-error>
              }
            </mat-form-field>
            @if (!requesting) {
              <button [disabled]="magicForm.invalid" class="full-width" mat-flat-button
              color="primary" type="submit">Login</button>
            }
            @if (requesting) {
              <button disabled class="full-width" mat-flat-button>wait <i
              class="fas fa-circle-notch fa-spin"></i></button>
            }
          </form>
        </mat-card-content>
      </mat-card>
    }
  </div>
</div>