<section>
  <div class="promo">
    @if (!isHandset && !isTablet) {
      <img src="../../assets/img/desktop.jpg" alt="rcpu promo" class="promo-image">
    }
    @if (isHandset || isTablet) {
      <img src="../../assets/img/mobile.jpg" alt="rcpu promo" class="promo-image">
    }
  </div>
  <div class="tickets">
    <div class="tickets-content">
      <mat-card-title>
        <h1 class="welcome">
          Welcome to the 2024 Edition of the Cancer Run
        </h1>
      </mat-card-title>
      <p class="notes">
        {{edition?.notes}}
      </p>
      <mat-divider style="margin: 12px auto;"></mat-divider>
      <div class="actions">
        <mat-card routerLink="/buy/get-kits" class="action" appearance="outlined">
          <mat-card-content>
            <img src="../../assets/img/icns/kit.png" alt="">
            <mat-card-subtitle>
              <h4 style="margin-bottom: 0;">
                GET KITS
              </h4>
            </mat-card-subtitle>
          </mat-card-content>
        </mat-card>
        <mat-card routerLink="/donations" class="action" appearance="outlined">
          <mat-card-content>
            <img src="../../assets/img/icns/donate.png" alt="">
            <mat-card-subtitle>
              <h4 style="margin-bottom: 0;">
                DONATE
              </h4>
            </mat-card-subtitle>
          </mat-card-content>
        </mat-card>
        <mat-card routerLink="/corporate/breakfast" class="action" appearance="outlined">
          <mat-card-content>
            <img src="../../assets/img/icns/breakfast.png" alt="">
            <mat-card-subtitle>
              <h4 style="margin-bottom: 0;">
                CORPORATE BREAKFAST
              </h4>
            </mat-card-subtitle>
          </mat-card-content>
        </mat-card>
        <!-- <mat-card class="action" appearance="outlined">
        <mat-card-content>
          <img src="../../assets/img/icns/bag.png" alt="">
          <mat-card-subtitle>
            <h4>
              MY KITS
            </h4>
          </mat-card-subtitle>
        </mat-card-content>
      </mat-card> -->
    </div>
    <!-- <mat-tab-group>
    <mat-tab label="REGISTER">
      <div style="padding-top: 12px;">
        <mat-card-title>
          <h3 style="text-align: center;">
            <button (click)="addKit()" mat-button class="full-width" color="primary">
              <span style="display: flex;">
                <span class="material-symbols-sharp ico">add</span>
                <span style="line-height: 32px;">Add Kit</span>
              </span>
            </button>
          </h3>
        </mat-card-title>
        <div class="flex-container">
          <div class="flex-item">
            <app-kit-size (removeItemEvent)="onRemove($event)" (changeItemEvent)="onChange($event)" *ngFor="let kit of cartItems" [kit]="kit"></app-kit-size>
          </div>
        </div>
        <button class="checkout" *ngIf="cartItems.length > 0" (click)="checkout()" mat-raised-button color="accent">
          <span style="display: flex;">
            <span class="material-symbols-sharp ico">shopping_cart_checkout</span>
            <span style="line-height: 32px;">Continue to Checkout</span>
          </span>
        </button>
      </div>
    </mat-tab>
    <mat-tab label="DONATE">
      <button routerLink="/donations" *ngIf="cartItems.length === 0" mat-flat-button color="accent">
        <span style="display: flex;">
          <span class="material-symbols-sharp ico">volunteer_activism</span>
          <span style="line-height: 32px;">Donate to the Program</span>
        </span>
      </button>
    </mat-tab>
  </mat-tab-group> -->

</div>
</div>
</section>
