import { Component, inject, OnInit } from '@angular/core';
import { MyKit } from '../models/ticketing.models';
import { TicketingService } from '../services/ticketing.service';
import { MatDialog } from '@angular/material/dialog';
import { PurchasedDetailComponent } from '../purchased-detail/purchased-detail.component';

@Component({
    selector: 'app-purchased',
    templateUrl: './purchased.component.html',
    styleUrls: ['./purchased.component.scss']
})
export class PurchasedComponent implements OnInit {

    kits?: MyKit[]

    #service = inject(TicketingService) 
    #dialog = inject(MatDialog) 

    ngOnInit(): void {
        this.#service.purchased().subscribe({
            next: data => {
                this.kits = data
            }
        })
    }

    onDetail(kit:MyKit){
        this.#dialog.open(PurchasedDetailComponent,{
            maxWidth:'360px',
            data: {
                cart: kit
            }
        })
    }

}
