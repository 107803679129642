<div class="flex-container">
  <div class="flex-item">
    <mat-card appearance="outlined">
      <mat-card-content style="text-align: center;">
        @if (status === 'failed') {
          <span style="color: red;" class="material-symbols-sharp ic">close</span>
        }
        @if (status === 'pending') {
          <span style="color: #999;" class="material-symbols-sharp ic">hourglass_bottom</span>
        }
        @if (status === 'successful') {
          <span style="color: green;" class="material-symbols-sharp ic">done_all</span>
        }
        <p>
          Your Card Transaction
          @if (status === 'failed') {
            <span>Failed</span>
          }
          @if (status === 'pending') {
            <span>is Pending</span>
          }
          @if (status === 'successful') {
            <span>Succeeded</span>
          }
        </p>
        <button mat-button color="primary" routerLink="/">
          Okay
        </button>
      </mat-card-content>
    </mat-card>
  </div>
</div>