<rcr-nav>
    <section style="padding: 12px;">
        <div class="flex-container">
            <div class="flex-item">
                <mat-card-title>
                    <h1 style="text-align: center;">
                        Purchased Kit Tickets
                    </h1>
                </mat-card-title>
                @for (k of kits; track $index) {
                <mat-card appearance="outlined" style="margin-bottom: 16px;">
                    <mat-card-content>
                        <mat-card-subtitle>
                            <h2 style="font-weight: 600;">
                                {{k.id}}
                            </h2>
                            @if (k.unclaimed.length > 0) {
                            <p>Some kits not yet collected</p>
                            <button (click)="onDetail(k)" mat-stroked-button color="primary">
                                SEE DETAILS NOW
                            </button>
                            } @else {
                            <p>All kits already collected</p>
                            }
                        </mat-card-subtitle>
                    </mat-card-content>
                </mat-card>
                } @empty {
                <mat-card appearance="outlined">
                    <mat-card-content style="text-align: center;">
                        <p>
                            It seems you have not purchased any kits yet.
                        </p>

                        <button routerLink="/buy/get-kits" mat-flat-button color="primary">
                            GET KITS HERE
                        </button>
                    </mat-card-content>
                </mat-card>
                }
            </div>
        </div>
    </section>
</rcr-nav>