import { Component, inject, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CheckUser, LoginModel, RegisterModel } from '../models/authx.models';
import { AuthService } from '../services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { SnackbarService } from '../shared/snackbar.service';

@Component({
    selector: 'app-authx',
    templateUrl: './authx.component.html',
    styleUrls: ['./authx.component.scss']
})
export class AuthxComponent {
    startForm?: FormGroup
    newForm?: FormGroup
    magicForm?: FormGroup
    requesting: boolean = false
    email = ''
    redirect = "/reg" // consider changing this to dashboard

    // injections
    #router = inject(Router)
    #service = inject(AuthService)
    #snackBar = inject(SnackbarService)
    #formBuilder = inject(FormBuilder)
    #platformId = inject(PLATFORM_ID)

    ngOnInit(): void {
        if (isPlatformBrowser(this.#platformId)) {
            let a = localStorage.getItem('a');
            this.redirect = localStorage.getItem('redir') ?? "/buy"
            if (a) {
                this.#router.navigate([this.redirect])
            }
            this.#buildStartForm()
        }
    }

    onCheck() {
        this.requesting = true;
        if (this.startForm?.valid) {
            let payload: CheckUser = {
                "email": this.startForm.value.email
            }
            if (isPlatformBrowser(this.#platformId)) {
                localStorage.setItem("email", this.startForm.value.email as string)
            }

            this.#service.checkUserExistance(payload).subscribe({
                next: data => {
                    if (data.exists) {
                        this._existing()
                    } else {
                        this.startForm = undefined
                        this.#buildNewForm()
                    }
                },
                error: e => {
                    if (e.error.error) {
                        this.#snackBar.errorSnackbar(e.error.error)
                    } else
                        console.error(e)
                }
            })
        }
    }

    onLogin() {
        this.requesting = true;
        if (this.magicForm?.valid) {
            let payload: LoginModel = {
                "email": this.email,
                "pin": this.magicForm.value.pin as string
            }
            this.#service.loginUser(payload).subscribe({
                next: data => {
                    this.#service.completeLogin(data.access_token, data.refresh_token)
                    this.requesting = false
                    if (isPlatformBrowser(this.#platformId)) {
                        localStorage.removeItem("email")
                    }

                    this.#router.navigate([this.redirect])
                    this.#snackBar.successSnackbar("Successfully logged in")
                },
                error: e => {
                    if (e.error.error) {
                        this.#snackBar.errorSnackbar(e.error.error)
                    } else
                        console.error(e)
                }

            })
        }
    }

    onNewUser() {
        this.requesting = true
        if (this.newForm?.valid) {
            let payload: RegisterModel = {
                "email": this.email,
                "name": this.newForm.value.fullname,
                "rcpu": true
            }
            this.#service.registerNewUser(payload).subscribe({
                next: data => {
                    this.#snackBar.successSnackbar(data.message)
                    this.newForm = undefined
                    this.#buildMagicForm()
                },
                error: e => {
                    if (e.error.error) {
                        this.#snackBar.errorSnackbar(e.error.error)
                    } else
                        console.error(e)
                }
            })
        }
    }

    private _existing() {
        if (isPlatformBrowser(this.#platformId)) {
            let payload: CheckUser = {
                "email": localStorage.getItem("email") ?? "",
                "rcpu": true
            }
            this.#service.getUserOTP(payload).subscribe({
                next: () => {
                    this.startForm = undefined
                    this.#snackBar.successSnackbar("Please enter the pin sent to your email")
                    this.#buildMagicForm()
                },
                error: e => {
                    if (e.error.error) {
                        this.#snackBar.errorSnackbar(e.error.error)
                    } else
                        console.error(e)
                }
            })
        }

    }

    #buildStartForm() {
        this.requesting = false
        this.startForm = this.#formBuilder.group({
            email: ['', Validators.required]
        })
    }

    #buildMagicForm() {
        this.requesting = false
        if (isPlatformBrowser(this.#platformId)) {
            this.email = localStorage.getItem("email") ?? ""
        }

        this.magicForm = this.#formBuilder.group({
            pin: ['', Validators.required]
        })
    }

    #buildNewForm() {
        this.requesting = false
        if (isPlatformBrowser(this.#platformId)) {
            this.email = localStorage.getItem("email") ?? ""
        }
        this.newForm = this.#formBuilder.group({
            fullname: ['', Validators.required]
        })
    }
}
