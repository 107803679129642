import { Component, inject, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TicketingService } from '../services/ticketing.service';
import { CartItem, Edition, EditionKit, EditionTicket, MakeCart } from '../models/ticketing.models';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TicketStubComponent } from '../shared/ticket-stub/ticket-stub.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { siteDetails } from '../global/variables';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

    edition?: Edition
    tickets: EditionTicket[] = []
    kits: EditionKit[] = []
    cartItems: CartItem[] = []
    isHandset = true
    isTablet = false

    // injections
    #service = inject(TicketingService)
    #router = inject(Router)
    #dialog = inject(MatDialog)
    #breakpointObserver = inject(BreakpointObserver)
    #meta = inject(Meta)
    #titleService = inject(Title)
    #platformId = inject(PLATFORM_ID) 

    ngOnInit(): void {
        this.#setMetaData()
        this.#getEdition()
        this.#getKits()
        this.#getTickets()
        this.#breakpoint()
    }

    addKit() {
        let dialogRef = this.#dialog.open(TicketStubComponent, {
            maxWidth: '360px',
            width: '80%',
            data: {
                tickets: this.tickets,
                kits: this.kits
            }
        })
        dialogRef.afterClosed().subscribe({
            next: (data?: CartItem) => {
                if (data) {
                    let existing = this.cartItems.find(i => i.size === data.size)
                    if (existing) {
                        let kits = +existing.count
                        let newVal = kits + +data.count
                        data.count = newVal
                        this.cartItems = this.cartItems.filter(i => i.size !== data.size)
                    }
                    this.cartItems.push(data)
                    this.cartItems.sort((a, b) => a.size - b.size)
                }
            }
        })
    }

    onRemove(item: CartItem) {
        this.cartItems = this.cartItems.filter(i => i.size !== item.size)
        this.cartItems.sort((a, b) => a.size - b.size)
    }

    onChange(item: CartItem) {
        this.cartItems = this.cartItems.filter(i => i.size !== item.size)
        this.cartItems.push(item)
        this.cartItems.sort((a, b) => a.size - b.size)
    }

    // onAddItem(item: CartItem) {
    //     this.cartItems.push(item)
    // }

    checkout() {
        const cart: MakeCart = {
            currency: 'UGX',
            items: this.cartItems
        }
        if (isPlatformBrowser(this.#platformId)) {
            localStorage.setItem("cart", JSON.stringify(cart))
        }
        this.#router.navigate(['/checkout'])
    }

    #getEdition() {
        this.#service.getEdition().subscribe({
            next: d => {
                this.edition = d
            }
        })
    }

    #getKits() {
        this.#service.getEditionKits().subscribe({
            next: d => {
                this.kits = d
            }
        })
    }

    #getTickets() {
        this.#service.getEditionTickets().subscribe({
            next: d => {
                this.tickets = d
            }
        })
    }

    #breakpoint() {
        this.#breakpointObserver.observe([Breakpoints.Handset])
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.isHandset = true
                    this.isTablet = false
                } else {
                    this.isHandset = false
                }
            })

        this.#breakpointObserver.observe([Breakpoints.TabletPortrait])
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.isTablet = true
                    this.isHandset = false
                } else {
                    this.isTablet = false
                }
            })
    }

    #setMetaData() {
        this.#titleService.setTitle(`Rotary Cancer Run - 2024 Edition`)
        this.#meta.addTags([
            { name: 'twitter:card', content: "summary_large_image" },
            { name: 'twitter:title', content: `Rotary Cancer Run - 2024 Edition` },
            { name: 'twitter:description', content: siteDetails.description },
            { name: 'twitter:image', content: siteDetails.large_img },
            { name: 'twitter:image:alt', content: siteDetails.image_alt },
            { name: 'twitter:site', content: siteDetails.handle },
            { name: 'twitter:creator', content: siteDetails.handle },
            { name: 'og:title', content: `Rotary Cancer Run - 2024 Edition` },
            { name: 'og:url', content: siteDetails.url },
            { name: 'og:description', content: siteDetails.description },
            { name: 'og:image', content: siteDetails.large_img },
            { name: 'og:image:alt', content: siteDetails.image_alt },
            { name: 'author', content: siteDetails.handle }
        ]);
    }

}
