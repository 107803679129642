import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterState } from '@angular/router';
declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'rcpu';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.injectScripts();
            this.handleRouteEvents();
        }
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.route.queryParams.subscribe(
                d => {
                    if (d['referer']) {
                        localStorage.setItem('referer', d['referer'])
                    }
                }
            )
        }
    }

    injectScripts() {
        const script = this.renderer.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-WCX5LXHKFM'
        this.renderer.appendChild(this.document.body, script)

        const scriptBody = this.renderer.createElement('script')
        scriptBody.type = 'text/javascript'
        scriptBody.text = `window.dataLayer = window.dataLayer || [];
                        function gtag() { dataLayer.push(arguments); }
                        gtag('js', new Date());
                        gtag('config', 'G-WCX5LXHKFM');`;

        this.renderer.appendChild(this.document.body, scriptBody);
    }

    handleRouteEvents() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(' - ');
                // this.titleService.setTitle(title);
                gtag('event', 'page_view', {
                    // page_title: title,
                    page_path: event.urlAfterRedirects,
                    page_location: this.document.location.href
                })
            }
        });
    }

    getTitle(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.title) {
            data.push(parent.snapshot.title);
        }
        if (state && parent && parent.firstChild) {
            data.push(...this.getTitle(state, parent.firstChild));
        }
        return data;
    }
}
