import { Component, inject } from '@angular/core';
import { MyKit } from '../models/ticketing.models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-purchased-detail',
  templateUrl: './purchased-detail.component.html',
  styleUrls: ['./purchased-detail.component.scss']
})
export class PurchasedDetailComponent {
    
    #dialogRef = inject(MatDialogRef<PurchasedDetailComponent>)
    data = inject<{cart: MyKit}>(MAT_DIALOG_DATA)

    close(){
        this.#dialogRef.close()
    }
}
